<template>
  <div
    :class="{ chosen: chosen }"
    class="ca-identifier ca-option"
    @click="clickIdentifier(identifier)"
  >
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    identifier: String,
  },
  computed: {
    ...mapGetters(["getChoice"]),
    chosen() {
      return this.getChoice("identifiers").includes(this.identifier);
    },
  },
  methods: {
    clickIdentifier(identifier) {
      this.$store.dispatch("updateIdentifier", identifier);
    },
  },
};
</script>

<style scoped>
.ca-identifier {
  width: 100%;
  height: 100%;
  max-width: 285px;
  margin: auto;
  background: #EAF7FC;
}
</style>
