<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">Let's get started!</h1>
        <h2 class="ca-subtitle">
          Select 1-2 identifiers that best motivate your marketing and sales
          strategy
        </h2>
      </v-col>
    </v-row>

    <v-row class="ca-content">
      <v-col cols="12">
      <v-row class="identifiers">
        <v-col cols="12" sm="6" md="4" class="col-five">
          <identifier identifier="security">
            <img
              class="identifier-img"
              src="../assets/images/icons/security.svg"
              alt=""
            />
            <h2 class="ca-card-title text-center text-capitalize">
              Security
            </h2>
            <div class="ca-id-wrapper">
              <ul>
                <li>We've got you covered</li>
                <li>Warranties/service/trust</li>
              </ul>
            </div>
          </identifier>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="col-five">
          <identifier identifier="passion">
            <img
              class="identifier-img"
              src="../assets/images/icons/passion.svg"
              alt=""
            />
            <h2 class="ca-card-title text-center text-capitalize">
              Passion
            </h2>
            <div class="ca-id-wrapper">
              <ul>
                <li>Find your dream car!</li>
                <li>Niche selection</li>
              </ul>
            </div>
          </identifier>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="col-five">
          <identifier identifier="simplicity">
            <img
              class="identifier-img"
              src="../assets/images/icons/simplicity.svg"
              alt=""
            />
            <h2 class="ca-card-title text-center text-capitalize">
              Simplicity
            </h2>
            <div class="ca-id-wrapper">
              <ul>
                <li>It's never been easier!</li>
                <li>Modern technology/convenience</li>
              </ul>
            </div>
          </identifier>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="col-five md-offset">
          <identifier identifier="value">
            <img
              class="identifier-img"
              src="../assets/images/icons/value.svg"
              alt=""
            />
            <h2 class="ca-card-title text-center text-capitalize">
              Value
            </h2>
            <div class="ca-id-wrapper">
              <ul>
                <li>We'll beat any deal around!</li>
                <li>Company incentives</li>
              </ul>
            </div>
          </identifier>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="col-five sm-offset">
          <identifier identifier="community">
            <img
              class="identifier-img"
              src="../assets/images/icons/community.svg"
              alt=""
            />
            <h2 class="ca-card-title text-center text-capitalize">
              Community
            </h2>
            <div class="ca-id-wrapper">
              <ul>
                <li>Our customers are like family</li>
                <li>Local efforts/involvement</li>
              </ul>
            </div>
          </identifier>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Identifier from "../components/Identifier.vue";
export default {
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Identifier,
  },
};
</script>

<style scoped>
.ca-id-wrapper li {
  list-style-type: none;
  margin-bottom: 6px;
  font-size: 15px;
}

.identifier-img {
  max-width: 150px;
  margin: auto auto 15px;
}

.identifiers ul {
  list-style-position: inside;
  padding: 0;
}

.identifiers {
  text-align: center;
}

@media (min-width: 1904px) {
  .col-five {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (max-width: 1903px) and (min-width: 960px) {
  .md-offset {
    margin-left: 17%;
  }
}

@media (max-width: 959px) and (min-width: 600px) {
  .sm-offset {
    margin-left: 25%;
  }
}
</style>
